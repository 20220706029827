<template>
  <div class="battery-item">
    <div class="select-wrap">
      <a-select
        v-model:value="id"
        :size="size"
        style="width: 300px"
        :options="options"
        @change="changeSelect"
      ></a-select>
    </div>
    <div class="title-wrap">
      <span :class="state == 0 ? 'span-active' : ''" @click="state = 0"
        >全部({{ batteryList.length }})</span
      ><span :class="state == 1 ? 'span-active' : ''" @click="state = 1"
        >在线(47)</span
      ><span :class="state == 2 ? 'span-active' : ''" @click="state = 2"
        >离线(9)</span
      ><span :class="state == 3 ? 'span-active' : ''" @click="state = 3"
        >未用(0)</span
      ><span :class="state == 4 ? 'span-active' : ''" @click="state = 4"
        >选择(0)</span
      >
    </div>
    <div class="sort-wrap">
      <span>
        <i class="iconfont icon-paixu"></i>
        排序
      </span>
      <span>
        <i class="iconfont icon-tianjia"></i>
        添加组
      </span>
    </div>
    <div class="battery-list" v-if="state == 0">
      <div class="default-group">
        <i class="iconfont icon-xiajiantou"></i>
        <span>默认组({{ batteryList.length }})</span>
      </div>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in batteryList"
          :key="index"
          :id="item.IMEI"
          :class="`${
            index == selectedIndex
              ? item.state == '静止'
                ? 'item-static-active'
                : 'battery-item-active'
              : ''
          }`"
          @click="selectedItem(index)"
        >
          <div
            class="top"
            :style="`color:${item.state == '静止' ? 'grey' : ''}`"
          >
            <div class="item-left">
              <i class="iconfont icon-xudianchi" style="color: green"></i>
              <span>{{ item.IMEI }}</span>
            </div>
            <div class="item-right">
              <div>
                <span
                  v-if="item.speed"
                  :title="'行驶速度为' + item.speed + 'km/h'"
                  >{{ item.speed }}{{ item.state }}</span
                >
                <span v-else>{{ item.state }}({{ item.time }})</span>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="index == selectedIndex">
            <span>关注</span><span>跟踪</span><span>回放</span
            ><span
              ><a-dropdown>
                <i @click.prevent> 更多 </i>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>设备信息</a-menu-item>
                    <a-sub-menu title="移动至组"
                      ><a-menu-item>3rd menu item</a-menu-item>
                      <a-menu-item>4th menu item</a-menu-item></a-sub-menu
                    >
                    <a-sub-menu key="test" title="设备指令">
                      <a-menu-item>远程断油电</a-menu-item>
                      <a-menu-item>远程恢复油电</a-menu-item>
                      <a-menu-item>位置设防</a-menu-item>
                      <a-menu-item>位置撤防</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item>下载轨迹</a-menu-item>
                  </a-menu>
                </template> </a-dropdown
              ><i class="iconfont icon-xiajiantou" style="font-size: 12px"></i
            ></span>
            <div class="menu"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state == 1" class="battery-list">
      <div class="default-group">
        <i class="iconfont icon-xiajiantou"></i>
        <span>默认组(47)</span>
      </div>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in batteryList"
          :key="index"
          :class="`${
            index == selectedIndex
              ? item.state == '静止'
                ? 'item-static-active'
                : 'battery-item-active'
              : ''
          }`"
          @click="selectedItem(index)"
        >
          <div
            class="top"
            :style="`color:${item.state == '静止' ? 'grey' : ''}`"
          >
            <div class="item-left">
              <i class="iconfont icon-xudianchi" style="color: green"></i>
              <span>{{ item.IMEI }}</span>
            </div>
            <div class="item-right">
              <div>
                <span
                  v-if="item.speed"
                  :title="'行驶速度为' + item.speed + 'km/h'"
                  >{{ item.speed }}{{ item.state }}</span
                >
                <span v-else>{{ item.state }}({{ item.time }})</span>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="index == selectedIndex">
            <span>关注</span><span>跟踪</span><span>回放</span
            ><span
              ><a-dropdown>
                <i @click.prevent> 更多 </i>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>设备信息</a-menu-item>
                    <a-sub-menu title="移动至组"
                      ><a-menu-item>3rd menu item</a-menu-item>
                      <a-menu-item>4th menu item</a-menu-item></a-sub-menu
                    >
                    <a-sub-menu key="test" title="设备指令">
                      <a-menu-item>远程断油电</a-menu-item>
                      <a-menu-item>远程恢复油电</a-menu-item>
                      <a-menu-item>位置设防</a-menu-item>
                      <a-menu-item>位置撤防</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item>下载轨迹</a-menu-item>
                  </a-menu>
                </template> </a-dropdown
              ><i class="iconfont icon-xiajiantou" style="font-size: 12px"></i
            ></span>
            <div class="menu"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state == 2" class="battery-list">
      <div class="default-group">
        <i class="iconfont icon-xiajiantou"></i>
        <span>默认组(9)</span>
      </div>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in batteryList"
          :key="index"
          :class="`${
            index == selectedIndex
              ? item.state == '静止'
                ? 'item-static-active'
                : 'battery-item-active'
              : ''
          }`"
          @click="selectedItem(index)"
        >
          <div
            class="top"
            :style="`color:${item.state == '静止' ? 'grey' : ''}`"
          >
            <div class="item-left">
              <i class="iconfont icon-xudianchi" style="color: green"></i>
              <span>{{ item.IMEI }}</span>
            </div>
            <div class="item-right">
              <div>
                <span
                  v-if="item.speed"
                  :title="'行驶速度为' + item.speed + 'km/h'"
                  >{{ item.speed }}{{ item.state }}</span
                >
                <span v-else>{{ item.state }}({{ item.time }})</span>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="index == selectedIndex">
            <span>关注</span><span>跟踪</span><span>回放</span
            ><span
              ><a-dropdown>
                <i @click.prevent> 更多 </i>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>设备信息</a-menu-item>
                    <a-sub-menu title="移动至组"
                      ><a-menu-item>3rd menu item</a-menu-item>
                      <a-menu-item>4th menu item</a-menu-item></a-sub-menu
                    >
                    <a-sub-menu key="test" title="设备指令">
                      <a-menu-item>远程断油电</a-menu-item>
                      <a-menu-item>远程恢复油电</a-menu-item>
                      <a-menu-item>位置设防</a-menu-item>
                      <a-menu-item>位置撤防</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item>下载轨迹</a-menu-item>
                  </a-menu>
                </template> </a-dropdown
              ><i class="iconfont icon-xiajiantou" style="font-size: 12px"></i
            ></span>
            <div class="menu"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state == 3" class="battery-list">
      <div class="default-group">
        <i class="iconfont icon-xiajiantou"></i>
        <span>默认组(0)</span>
      </div>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in batteryList"
          :key="index"
          :class="`${
            index == selectedIndex
              ? item.state == '静止'
                ? 'item-static-active'
                : 'battery-item-active'
              : ''
          }`"
          @click="selectedItem(index)"
        >
          <div
            class="top"
            :style="`color:${item.state == '静止' ? 'grey' : ''}`"
          >
            <div class="item-left">
              <i class="iconfont icon-xudianchi" style="color: green"></i>
              <span>{{ item.IMEI }}</span>
            </div>
            <div class="item-right">
              <div>
                <span
                  v-if="item.speed"
                  :title="'行驶速度为' + item.speed + 'km/h'"
                  >{{ item.speed }}{{ item.state }}</span
                >
                <span v-else>{{ item.state }}({{ item.time }})</span>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="index == selectedIndex">
            <span>关注</span><span>跟踪</span><span>回放</span
            ><span
              ><a-dropdown>
                <i @click.prevent> 更多 </i>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>设备信息</a-menu-item>
                    <a-sub-menu title="移动至组"
                      ><a-menu-item>3rd menu item</a-menu-item>
                      <a-menu-item>4th menu item</a-menu-item></a-sub-menu
                    >
                    <a-sub-menu key="test" title="设备指令">
                      <a-menu-item>远程断油电</a-menu-item>
                      <a-menu-item>远程恢复油电</a-menu-item>
                      <a-menu-item>位置设防</a-menu-item>
                      <a-menu-item>位置撤防</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item>下载轨迹</a-menu-item>
                  </a-menu>
                </template> </a-dropdown
              ><i class="iconfont icon-xiajiantou" style="font-size: 12px"></i
            ></span>
            <div class="menu"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state == 4" class="battery-list">
      <div class="default-group">
        <i class="iconfont icon-xiajiantou"></i>
        <span>默认组(0)</span>
      </div>
      <div class="item-wrap">
        <div
          class="item"
          v-for="(item, index) in batteryList"
          :key="index"
          :class="`${
            index == selectedIndex
              ? item.state == '静止'
                ? 'item-static-active'
                : 'battery-item-active'
              : ''
          }`"
          @click="selectedItem(index)"
        >
          <div
            class="top"
            :style="`color:${item.state == '静止' ? 'grey' : ''}`"
          >
            <div class="item-left">
              <i class="iconfont icon-xudianchi" style="color: green"></i>
              <span>{{ item.IMEI }}</span>
            </div>
            <div class="item-right">
              <div>
                <span
                  v-if="item.speed"
                  :title="'行驶速度为' + item.speed + 'km/h'"
                  >{{ item.speed }}{{ item.state }}</span
                >
                <span v-else>{{ item.state }}({{ item.time }})</span>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="index == selectedIndex">
            <span>关注</span><span>跟踪</span><span>回放</span
            ><span
              ><a-dropdown>
                <i @click.prevent> 更多 </i>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>设备信息</a-menu-item>
                    <a-sub-menu title="移动至组"
                      ><a-menu-item>3rd menu item</a-menu-item>
                      <a-menu-item>4th menu item</a-menu-item></a-sub-menu
                    >
                    <a-sub-menu key="test" title="设备指令">
                      <a-menu-item>远程断油电</a-menu-item>
                      <a-menu-item>远程恢复油电</a-menu-item>
                      <a-menu-item>位置设防</a-menu-item>
                      <a-menu-item>位置撤防</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item>下载轨迹</a-menu-item>
                  </a-menu>
                </template> </a-dropdown
              ><i class="iconfont icon-xiajiantou" style="font-size: 12px"></i
            ></span>
            <div class="menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as batteryGpsLastApi from '@/api/rnt/batteryGpsLast.js'
export default {
  data() {
    return {
      options: [],
      id: null,
      batteryList: [
        {
          lanlgt: [118.08923, 24.479406],
          IMEI: 'BM5080323932',
          state: '静止',
          time: '19时19分'
        },
        {
          lanlgt: [118.06923, 24.479406],
          IMEI: 'BM5080323882',
          state: '行驶',
          time: '19时19分',
          speed: 17
        },
        {
          lanlgt: [118.07923, 24.479406],
          IMEI: 'BM5080325782',
          state: '离线',
          time: '6时19分'
        }
      ],
      selectedIndex: null,
      state: 0
    }
  },
  created() {
    this.getAllIdData()
  },
  methods: {
    setSelectItem(IMEI) {
      const index = this.batteryList.findIndex((item) => {
        return item.IMEI === IMEI
      })
      this.selectedIndex = index
      this.id = IMEI
      document.getElementById(this.id).scrollIntoView({ block: 'center' })
      console.log('index', index)
    },

    // 监听选择器改变事件
    changeSelect(e) {
      this.id = e
      this.batteryList.map((item, index) => {
        if (item.IMEI === this.id) {
          this.selectedIndex = index
          // this.id = this.batteryList[index].IMEI
          this.$emit('infoWindow', this.batteryList[index].lanlgt, this.id)
          document.getElementById(this.id).scrollIntoView({ block: 'center' })
        }
      })
    },
    // 点击选择的设备
    selectedItem(index) {
      this.selectedIndex = index
      this.id = this.batteryList[index].IMEI
      this.$emit('infoWindow', this.batteryList[index].lanlgt, this.id)
    },
    // 加载所有设备id
    getAllIdData() {
      batteryGpsLastApi.all().then((res) => {
        if (res.code === 0) {
          // console.log('res', res.data)
          const arr = res.data.map((item) => {
            return item.deviceId
          })
          arr.map((item) => {
            const value = {
              value: item
            }
            this.options.push(value)
          })
          this.batteryList = res.data.map((item) => {
            return {
              IMEI: item.deviceId,
              lanlgt: [item.longitude, item.latitude],
              state: '在线',
              time: '6时19分'
              // speed: 17
            }
          })
        }
      })
      // const arr = ['BM5080323932', 'BM5080323882', 'BM5080325782']
    }
  }
}
</script>
<style lang='less' scoped>
.item-static-active {
  font-size: 15px;
  color: green;
  border: 1px solid #b8b9b9;
  margin-left: 10px;
  background-color: #eef2f3;
}
.battery-item-active {
  font-size: 15px;
  color: #5461e0;
  border: 1px solid #a4d4f5;
  margin-left: 10px;
  background-color: #ebf9ff;
}
.item-wrap {
  height: 198px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.item {
  margin: 0 10px;
  padding: 5px 0;
  .bottom {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    span {
      color: grey;
      &::after {
        display: inline-block;
        width: 1px;
        height: 12px;
        content: '';
        background-color: grey;
        margin: 0 5px;
        vertical-align: middle;
      }
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
    & span:nth-child(4)::after {
      display: none;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 10px;

    .item-left {
      span {
        font-weight: 500;
        margin-left: 5px;
        font-size: 13px;
      }
    }
    .item-right {
    }
  }
}
.battery-list {
  // height: calc(100% - 200);
  // overflow: hidden;
  // overflow-y: scroll;
  .default-group {
    padding-left: 5px;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    i {
      color: gray;
    }
    span {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}
.sort-wrap {
  background-color: #ededed;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  padding: 5px;
  span {
    margin-right: 20px;
  }
  .iconfont {
    font-size: 12px;
  }
}
.battery-item {
  .select-wrap {
    padding: 5px;
    background-color: #fff;
  }
}
.title-wrap {
  background-color: #f2f6f7;
  border-bottom: 1px solid #a9b5c4;
  span {
    display: inline-block;
    padding: 10px 3px;
    margin-top: 2px;
    margin-left: 5px;
    font-weight: bold;
  }
  .span-active {
    border: 1px solid #a9b5c4;
    border-bottom: none;
    border-radius: 3px;
    color: #3d4f98;
  }
}
</style>
